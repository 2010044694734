import React, { useContext, useState } from 'react'
import { Box, Typography, Grid, Avatar, Stack, Tooltip, TooltipProps, tooltipClasses, Badge } from '@mui/material'
import { RosLogo } from '../../assets/img'
import { styles } from './header-style'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled, alpha } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as Button_menu } from '../../assets/icon/menu/button_menu_blue.svg'
import { ReactComponent as EducationIcon } from '../../assets/icon/menu/education.svg'
import { ReactComponent as UsersIcon } from '../../assets/icon/menu/users.svg'
import { ReactComponent as AdvertisingIcon } from '../../assets/icon/menu/advertising.svg'
import { ReactComponent as GroupIcon } from '../../assets/icon/menu/group.svg'
import { ReactComponent as HelpIcon } from '../../assets/icon/menu/help.svg'
import { ReactComponent as PrivacyIcon } from '../../assets/icon/menu/privacy.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icon/menu/exit.svg'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import MessageIcon from '../../assets/icon/chat/message'
import { ReactComponent as Message } from '../../assets/icon/group/message.svg'
import NoteIcon from '../../assets/icon/chat/note'
import SettingsIcon from '../../assets/icon/chat/settings'
import UserIcon from '../../assets/icon/chat/user'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { useCallback } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { MAIN, USERS, RIGHTS, ROLES, GROUPS, FAQ, SETTINGS } from '../../constants/routes'
import { PersonalProfile } from '../profile/PersonalProfile'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../redux/store'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { LogoutModal } from './modals/LogoutModal'
import { LanguageContext, translate } from '../../constants/lang'
import './styles/AppBarComponent.css'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import { ROOM_AUTHORITY, ROOM_NETWORK, ROOM_PRESS } from '../../constants/chat_constant'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#4d4d4d',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#4d4d4d',
  },
}))

export let AppBarComponent = ({ openEditParticipants, openChatSettings }: any) => {
  const navigate = useNavigate()
  let alias = useSelector((store: AppStateType) => store.Profile.profile?.alias)
  let lang = useSelector((store: AppStateType) => store.Auth.lang)
  let nameRole = useSelector((store: AppStateType) => store.Chat.nameRole)
  let nameDrill = useSelector((store: AppStateType) => store.Chat.nameDrill)
  let wasReaded = useSelector((store: AppStateType) => store.Chat.wasReadedGeneralChat)
  let isAdmin = useSelector((store: AppStateType) => store.Rights.rights).some((i) => i.name === 'admin')
  const isExpert = useSelector((store: AppStateType) => store.Chat.isExpert)
  const location = useLocation().pathname
  const drillId = location.includes('drill') && location.slice(7, 43)
  const isGeneralChat = location.includes('general')
  // const handleOnClick = useCallback(() => navigate('/chat', { replace: true }), [navigate])
  const [isOpenUserProfile, setOpen] = React.useState(false)
  const openUserProfile = () => setOpen(true)
  const closeUserProfile = () => setOpen(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const typeRoleOfThisDrill = useSelector((store: AppStateType) => store.Chat.socialRoleType)
  //для модального окна разлогинится
  const [isOpenLogout, setOpenLogout] = React.useState(false)
  const openLogout = () => setOpenLogout(true)
  const closeLogout = () => setOpenLogout(false)
  const { language, changeLanguage } = useContext(LanguageContext)
  const open = Boolean(anchorEl)
  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const selectMenuItem = (LINK: string) => {
    closeMenu()
    navigate(LINK, { replace: true })
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const changeLanguages = (event: SelectChangeEvent) => {
    if (event.target.value === 'ru') {
      changeLanguage('ru')
    } else if (event.target.value === 'en') {
      changeLanguage('en')
    }
  }

  let translatedRole
  const role = typeRoleOfThisDrill?.split('.')[4]
  if (role == ROOM_AUTHORITY) translatedRole = translate('pressService', language)
  if (role == ROOM_PRESS) translatedRole = translate('massMedia', language)
  if (role == ROOM_NETWORK) translatedRole = translate('socialNetworks', language)

  const [age, setAge] = React.useState(lang!)

  const goToChat = () => {
    isGeneralChat
      ? navigate(`/drill/${drillId}`, { replace: true })
      : navigate(`/drill/${drillId}/general`, { replace: true })
  }

  const StyledMenu = styled((props: any) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 16,
      marginTop: theme.spacing(1),
      minWidth: 150,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
        '& .MuiSvgIcon-root': {
          fontSize: 18,
        },
        '&:active': { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) },
      },
    },
  }))

  return (
    <AppBar style={{ background: '#FFFFFF' }} position="static">
      <Toolbar>
        {/* <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}> */}
        <IconButton onClick={() => navigate(-1)} color="primary">
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <Grid item onClick={useCallback(() => navigate('/', { replace: true }), [navigate])}>
          <Box component="img" sx={styles.img} src={RosLogo} loading="lazy" />
        </Grid>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, fontWeight: 800, fontSize: 24, lineHeight: 2, color: 'black' }}
        >
          {location.includes('drill') && nameDrill}
        </Typography>
        {location.includes('drill') && (
          <Stack direction="row" spacing={2} sx={{ mr: '2%' }}>
            <CustomTooltip title={translate(isGeneralChat ? 'goToDrills' : 'commonChat', language)}>
              {wasReaded ? (
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'uppercase' }}
                  endIcon={isGeneralChat ? <ViewColumnIcon fontSize={'large'} /> : <MessageIcon color="#118EFF" />}
                  size="small"
                  onClick={() => goToChat()}
                >
                  {translate(isGeneralChat ? 'chats' : 'commonChat', language)}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'uppercase' }}
                  onClick={() => goToChat()}
                  endIcon={
                    <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                      <MessageIcon color="#118EFF" />
                    </Badge>
                  }
                  size="medium"
                >
                  {translate('commonChat', language)}
                </Button>
              )}
            </CustomTooltip>
            <CustomTooltip title={translate('journal', language)}>
              <IconButton component={Link} to={`/drill/${drillId}/journal`}>
                <NoteIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title={translate('editParticipants', language)}>
              <IconButton onClick={openEditParticipants}>
                <UserIcon />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title={translate('displayingChats', language)}>
              <IconButton onClick={openChatSettings}>
                <SettingsIcon />
              </IconButton>
            </CustomTooltip>
          </Stack>
        )}
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disableElevation
          onClick={openMenu}
        >
          {translate('menu', language)}
          <SvgIcon color={'primary'}>
            <Button_menu />
          </SvgIcon>
        </Button>
      </Toolbar>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem divider={true} onClick={closeMenu} disableRipple>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Box onClick={openUserProfile}>
              <Avatar />
            </Box>
            <Grid onClick={openUserProfile} sx={{ mx: 1 }}>
              {alias}
              {location.includes('drill') && <Grid>{nameRole}</Grid>}
            </Grid>
            <Grid>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Язык</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={language}
                  label="Age"
                  onChange={changeLanguages}
                  size={'small'}
                >
                  <MenuItem value={'ru'}>🇷🇺</MenuItem>
                  <MenuItem value={'en'}>🇬🇧󠁢󠁿</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </MenuItem>
        {location.includes('drill') && (
          <MenuItem divider={true} sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}>
            <Box>
              <Grid className="custom-text">{translate('roleType', language)}</Grid>
              <Grid>{translatedRole}</Grid>
              {isExpert && <Grid>{'Эксперт'}</Grid>}
            </Box>
          </MenuItem>
        )}
        {/*<Divider sx={{ my: 0.5 }} />*/}
        <MenuItem onClick={() => selectMenuItem(MAIN)} disableRipple>
          <SvgIcon color="primary" component={EducationIcon} />
          {translate('labelDrill', language)}
        </MenuItem>
        <MenuItem onClick={() => selectMenuItem(USERS)} disableRipple>
          <SvgIcon color="primary" component={UsersIcon} />
          {translate('users', language)}
        </MenuItem>
        <MenuItem onClick={() => selectMenuItem(RIGHTS)} disableRipple>
          <SvgIcon color="primary" component={PrivacyIcon} />
          {translate('accessRights', language)}
        </MenuItem>
        <MenuItem onClick={() => selectMenuItem(ROLES)} disableRipple>
          <SvgIcon color="primary" component={AdvertisingIcon} />
          {translate('roles', language)}
        </MenuItem>
        <MenuItem onClick={() => selectMenuItem(GROUPS)} disableRipple>
          <SvgIcon color="primary" component={GroupIcon} />
          {translate('groups', language)}
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={() => selectMenuItem(SETTINGS)} disableRipple>
            <SvgIcon color="primary" style={{ color: '#a7b2c4' }} component={SettingsRoundedIcon} />
            {translate('settings', language)}
          </MenuItem>
        )}
        <MenuItem onClick={() => selectMenuItem(FAQ)} disableRipple>
          <SvgIcon color="primary" component={HelpIcon} />
          {translate('help', language)}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            openLogout()
            closeMenu()
          }}
          disableRipple
        >
          <SvgIcon color="primary" component={LogoutIcon} />
          {translate('labelExit', language)}
        </MenuItem>
      </StyledMenu>
      <PersonalProfile
        openUserProfile={openUserProfile}
        closeUserProfile={closeUserProfile}
        isOpenUserProfile={isOpenUserProfile}
      />
      <LogoutModal closeLogout={closeLogout} isOpenLogout={isOpenLogout} />
    </AppBar>
  )
}
